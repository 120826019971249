<template>
	<!-- Start Template Root Div -->
	<div>
		<WaveLoader v-if="searchSurfWaiversLoading" />

		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div>
							<h1 class="text-2xl">Customer Check-In</h1>
						</div>
						<div>
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="container mx-auto py-3 px-4 lg:px-8">
			<section class="mb-5">
				<p>
					Welcome to The Wave. Before you can come on-site you must
					check everyone in, and make sure all relevant waivers are
					complete.
				</p>

				<div class="w-full md:w-3/4 lg:w-1/2 flex items-end mt-5">
					<div>
						<label
							class="block text-gray-700 text-sm mb-2"
							for="surname"
						>
							Enter your Surname so we can find your booking
						</label>
						<input
							class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							type="text"
							placeholder="Your last name"
							v-model="surname"
						/>
					</div>

					<button
						class="w-auto mx-4 bg-pear-500 hover:bg-pear-700 hover:border-0 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
						@click="searchSessions"
					>
						Continue
					</button>
				</div>
			</section>

			<section>
				<p v-if="surfers.length > 1">
					We've found several people with sessions today, please
					choose yourself from the list below:
				</p>
				<p v-if="surfers.length === 1">
					We've found the following session for you:
				</p>
				<p
					v-if="
						searchSurfWaiversSuccess === true &&
							surfers.length === 0 &&
							surname !== '' &&
							surname === surnameSearched
					"
				>
					We're looking for the last name of "{{ surnameSearched }}".
					<br />
					Please contact a Wavemaker before proceeding on site.
				</p>
				<p
					v-if="
						searchSurfWaiversSuccess === false &&
							searchSurfWaiversError &&
							searchSurfWaiversError.length > 0
					"
				>
					{{ searchSurfWaiversError }}
				</p>
				<div
					v-for="(surfer, index) in surfers"
					:key="'surfer-result-' + index"
					class="flex flex-wrap items-center justify-around -mx-2 lg:-mx-4"
				>
					<div class="flex-auto flex items-center">
						<div class="md:w-1/2 m-2 lg:m-4">
							<p class="mb-1">
								{{ surfer.lastName }}, {{ surfer.firstName }}
							</p>
							<p class="text-xs" v-html="surfer.sessionNames"></p>
						</div>
						<div class="md:w-1/2 m-2 lg:m-4">
							<p>Session(s) at {{ surfer.sessionTimes }}</p>
						</div>
					</div>
					<div class="w-1/2 md:w-1/3 m-2 lg:m-4">
						<button
							class="w-full md:w-3/4 lg:w-1/2 bg-pear-500 hover:bg-pear-700 hover:border-0 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
							@click="
								setUser({
									name: surfer.firstName,
									reservationAk: surfer.reservationAk
								})
							"
						>
							This is me
						</button>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>
<script>
	import { mapState, mapActions } from "vuex";

	import WaveLogo from "@/components/WaveLogo.vue";
	import WaveLoader from "@/components/WaveLoader.vue";

	export default {
		components: {
			WaveLogo,
			WaveLoader
		},
		data: () => ({
			surname: "",
			surnameSearched: "",
			accountAk: ""
		}),
		computed: {
			// Mapped State From Vuex Namespaced Module "surfers" Store:
			...mapState("surfers", [
				"searchSurfWaiversLoading",
				"searchSurfWaiversResults",
				"searchSurfWaiversSuccess",
				"searchSurfWaivers",
				"searchSurfWaiversError"
			]),
			activeDate() {
				return this.$moment()
					.utc()
					.startOf("day")
					.toISOString();
			},
			surfers() {
				return this.searchSurfWaivers.filter(
					surfer => surfer.onSite === false
				);
			}
		},
		methods: {
			...mapActions([
				"surfers/searchSurfersWaivers",
				"surfers/setActiveSurfWaiverUser"
			]),
			searchSessions() {
				this.surnameSearched = this.surname;
				this["surfers/searchSurfersWaivers"]({
					date: this.activeDate,
					last_name: this.surname //Object keys must match python names to work
				});
			},
			setUser(data) {
				this["surfers/setActiveSurfWaiverUser"](data.name);
				this.$router.push({
					name: "CheckIn2",
					params: {
						reservationAk: data.reservationAk
							? data.reservationAk
							: "unreserved",
						lastName: this.surname
					}
				});
			}
		}
	};
</script>
