var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.searchSurfWaiversLoading)?_c('WaveLoader'):_vm._e(),_c('header',{staticClass:"w-full sticky top-0 z-10"},[_c('div',{staticClass:"w-full bg-gray-700"},[_c('div',{staticClass:"container mx-auto py-2 lg:p-8 lg:py-3"},[_c('div',{staticClass:"w-full flex justify-between items-center p-2 text-white"},[_c('div',{staticClass:"text-white p-5"},[_c('WaveLogo',{staticClass:"cursor-pointer h-16 md:h-12 lg:h-16"})],1),_vm._m(0),_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(),"dddd Do MMMM"))+" ")])])])])])]),_c('main',{staticClass:"container mx-auto py-3 px-4 lg:px-8"},[_c('section',{staticClass:"mb-5"},[_c('p',[_vm._v(" Welcome to The Wave. Before you can come on-site you must check everyone in, and make sure all relevant waivers are complete. ")]),_c('div',{staticClass:"w-full md:w-3/4 lg:w-1/2 flex items-end mt-5"},[_c('div',[_c('label',{staticClass:"block text-gray-700 text-sm mb-2",attrs:{"for":"surname"}},[_vm._v(" Enter your Surname so we can find your booking ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.surname),expression:"surname"}],staticClass:"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",attrs:{"type":"text","placeholder":"Your last name"},domProps:{"value":(_vm.surname)},on:{"input":function($event){if($event.target.composing)return;_vm.surname=$event.target.value}}})]),_c('button',{staticClass:"w-auto mx-4 bg-pear-500 hover:bg-pear-700 hover:border-0 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full",on:{"click":_vm.searchSessions}},[_vm._v(" Continue ")])])]),_c('section',[(_vm.surfers.length > 1)?_c('p',[_vm._v(" We've found several people with sessions today, please choose yourself from the list below: ")]):_vm._e(),(_vm.surfers.length === 1)?_c('p',[_vm._v(" We've found the following session for you: ")]):_vm._e(),(
					_vm.searchSurfWaiversSuccess === true &&
						_vm.surfers.length === 0 &&
						_vm.surname !== '' &&
						_vm.surname === _vm.surnameSearched
				)?_c('p',[_vm._v(" We're looking for the last name of \""+_vm._s(_vm.surnameSearched)+"\". "),_c('br'),_vm._v(" Please contact a Wavemaker before proceeding on site. ")]):_vm._e(),(
					_vm.searchSurfWaiversSuccess === false &&
						_vm.searchSurfWaiversError &&
						_vm.searchSurfWaiversError.length > 0
				)?_c('p',[_vm._v(" "+_vm._s(_vm.searchSurfWaiversError)+" ")]):_vm._e(),_vm._l((_vm.surfers),function(surfer,index){return _c('div',{key:'surfer-result-' + index,staticClass:"flex flex-wrap items-center justify-around -mx-2 lg:-mx-4"},[_c('div',{staticClass:"flex-auto flex items-center"},[_c('div',{staticClass:"md:w-1/2 m-2 lg:m-4"},[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(surfer.lastName)+", "+_vm._s(surfer.firstName)+" ")]),_c('p',{staticClass:"text-xs",domProps:{"innerHTML":_vm._s(surfer.sessionNames)}})]),_c('div',{staticClass:"md:w-1/2 m-2 lg:m-4"},[_c('p',[_vm._v("Session(s) at "+_vm._s(surfer.sessionTimes))])])]),_c('div',{staticClass:"w-1/2 md:w-1/3 m-2 lg:m-4"},[_c('button',{staticClass:"w-full md:w-3/4 lg:w-1/2 bg-pear-500 hover:bg-pear-700 hover:border-0 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full",on:{"click":function($event){return _vm.setUser({
								name: surfer.firstName,
								reservationAk: surfer.reservationAk
							})}}},[_vm._v(" This is me ")])])])})],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"text-2xl"},[_vm._v("Customer Check-In")])])
}]

export { render, staticRenderFns }